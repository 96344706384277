// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { chat } from './chat';
import { showSuccessToast } from 'utils/showSuccessToast';
import { DepartamentsStateProps } from 'types/departaments';

export const url = process.env.REACT_APP_API_URL + '/api/department/v1/departments';

const initialState: DepartamentsStateProps = {
  departaments: []
};

export const departaments = createSlice({
  name: 'departaments',
  initialState,
  reducers: {
    // SET Departaments
    setDepartaments(state, action) {
      state.departaments = action.payload;
    }
  }
});

export function getDepartaments() {
  return async () => {
    try {
      const response = await axios.get(url);
      dispatch(departaments.actions.setDepartaments(response.data));
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function editDepartament(departamentId: string, name: string) {
  return async () => {
    try {
      await axios.put(`${url}/${departamentId}`, { name });
      showSuccessToast('Departamento editada com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function addDepartament(name: string) {
  return async () => {
    try {
      await axios.post(url, { name });
      showSuccessToast('Departamento adicionada com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export function deleteDepartament(departamentId: string) {
  return async () => {
    try {
      await axios.delete(`${url}/${departamentId}`);
      showSuccessToast('Departamento removida com sucesso');
    } catch (error) {
      dispatch(chat.actions.hasError(error));
    }
  };
}

export default departaments.reducer;
