import { lazy } from 'react';

// project-imports
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - applications
const AppChat = Loadable(lazy(() => import('pages/apps/chat/chat')));
const AppGroupContacts = Loadable(lazy(() => import('pages/apps/customers/group')));
const AppListContats = Loadable(lazy(() => import('pages/apps/customers/list')));
const AppCalendar = Loadable(lazy(() => import('pages/apps/calendar')));
const AppDashBoard = Loadable(lazy(() => import('pages/apps/dash-board')));
const AppService = Loadable(lazy(() => import('pages/apps/atendimento')));
const AppServiceReports = Loadable(lazy(() => import('pages/apps/services/reports')));
const AppChannels = Loadable(lazy(() => import('pages/apps/channels/')));
const AppDepartaments = Loadable(lazy(() => import('pages/apps/departaments/')));
const AppCampaigns = Loadable(lazy(() => import('pages/apps/campaigns')));
const AppDashboardCampanhas = Loadable(lazy(() => import('pages/apps/dashboard-campanhas')));
const AppUsers = Loadable(lazy(() => import('pages/apps/users')));
const AppTags = Loadable(lazy(() => import('pages/apps/tags')));

// ==============================|| MAIN ROUTES ||============================== //

const AppsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'apps',
          children: [
            {
              path: 'chat',
              element: <AppChat />
            },
            {
              path: 'calendar',
              element: <AppCalendar />
            },
            {
              path: 'dashboard',
              element: <AppDashBoard />
            },
            {
              path: 'atendimento',
              element: <AppService />
            },
            {
              path: 'relatorio-atendimentos',
              element: <AppServiceReports />
            },
            {
              path: 'contatos',
              children: [
                {
                  path: 'lista',
                  element: <AppListContats />
                },
                {
                  path: 'grupos',
                  element: <AppGroupContacts />
                }
              ]
            },
            {
              path: 'canais',
              element: <AppChannels />
            },
            {
              path: 'departamento',
              element: <AppDepartaments />
            },
            {
              path: 'campanhas',
              element: <AppCampaigns />
            },
            {
              path: 'dashboard-campanhas',
              element: <AppDashboardCampanhas />
            },
            {
              path: 'usuario',
              element: <AppUsers />
            },
            {
              path: 'tags',
              element: <AppTags />
            }
          ]
        }
      ]
    }
  ]
};

export default AppsRoutes;
